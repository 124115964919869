<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="faultBtn">
          <div :class="this.auditStatus=== ''? 'active' : 'none'" @click="btnClick('')">
            全部记录({{applyNumber.all || 0}})
          </div>
          <div :class="this.auditStatus=== '00'? 'active' : 'none'" @click="btnClick('00')">
            上账记录({{applyNumber.addNum || 0}})
          </div>
          <div :class="this.auditStatus=== '01'? 'active' : 'none'" @click="btnClick('01')">
            下账记录({{applyNumber.subNum || 0}})
          </div>
      </div>
      <div>
        <el-button size="small" type="success" @click="handBtn('add')">上账</el-button>
        <el-button size="small" type="danger" @click="handBtn('sub')">下账</el-button>
        <el-button size="small" :loading="isExport" type="warning" icon="el-icon-download" @click="clickExcel">导出表格</el-button>
      </div>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="商户类型" slot="accountType" align="center">
        <template slot-scope="{ row }">
          <span>{{row.accountType == 'merchant'?'回收商':'门店商'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户名称" slot="enterpriseName" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.enterpriseName"
            class="item"
            effect="dark"
            :content="row.enterpriseName"
            placement="top"
          >
            <div class="Remarks">
            {{ row.enterpriseName }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="资金账户" slot="operationAccount" align="center">
        <template slot-scope="{ row }">
          <span>{{row.operationAccount == '01'?'回收账户':row.operationAccount == '02'? '活动账户' : '差异基金账户'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="详细地址"
        width="130rpx"
        slot="enterpriseAddress"
        align="center"
      >
        <template slot-scope="{ row }">
        <el-tooltip
          v-if="row.enterpriseAddress"
          class="item"
          effect="dark"
          :content="row.enterpriseAddress"
          placement="top"
        >
          <div class="Remarks">
          {{ row.enterpriseAddress}}
          </div>
        </el-tooltip>
        <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="上账/下账" slot="opt" align="center">
        <template slot-scope="{ row }">
          <span :style="row.opt == '01' && 'color:#FF687B'">{{row.opt == '00'?'上账':'下账'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="转款方式" slot="payeeWay" align="center">
          <template slot-scope="{ row }">
            {{ row.payeeWay || '--'}}
          </template>
      </el-table-column>
      <el-table-column label="凭证" slot="voucher" align="center">
          <template slot-scope="{ row }">
            <span style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;" @click="seeVoucher(row)">查看</span>
          </template>
      </el-table-column>
      <el-table-column label="微信流水号" slot="wechatFollowNo" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.wechatFollowNo"
            class="item"
            effect="dark"
            :content="row.wechatFollowNo"
            placement="top"
          >
            <div class="Remarks">
              {{ row.wechatFollowNo}}
            </div>
          </el-tooltip>
          <span v-else style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;" @click="setFollowNo(row,'add')">添加</span>
          <span v-if="row.wechatFollowNo" style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;" @click="setFollowNo(row,'edit')">修改</span>
        </template>
      </el-table-column>
      <el-table-column
        label="核对账单"
        align="center"
        slot="callAccounts"
      >
        <template v-slot="{row}">
          <el-button v-if="row.approvedType === '00'" type="danger" @click="callAccountsClick(row)" size="mini">核账</el-button>
          <p v-else-if="row.approvedType === '01'" class="lv_fc">已通过</p>
          <p v-else-if="row.approvedType === '02'" class="lv_fc_red">未通过</p>
          <p v-else>无需核账</p>
          <p v-if="row.approvedType === '01'" class="lv_fc_line" @click="callAccountsClick(row)">查看记录</p>
          <p v-if="row.approvedType === '02'" class="lv_fc_red lv_f_line" style="cursor: pointer;" @click="callAccountsClick(row)">查看记录</p>
        </template>
      </el-table-column>
      <el-table-column
        label="备注说明"
        width="130rpx"
        slot="note"
        align="center"
      >
        <template slot-scope="{ row }">
        <el-tooltip
          v-if="row.note"
          class="item"
          effect="dark"
          :content="row.note"
          placement="top"
        >
          <div class="Remarks">
            {{ row.note}}
          </div>
        </el-tooltip>
        <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column width="240" label="凭证" slot="operation" align="center">
          <template slot-scope="{ row }">
              <el-button size="mini" style="margin-bottom:5px" type="primary" round @click="handleBtn(row)">操作记录</el-button>
              <el-button size="mini" type="success" round @click="handleBtn(row,'remark')">{{row.note?'修改备注':'添加备注'}}</el-button>
          </template>
      </el-table-column>
    </GlobalTable>
    <!-- 备注弹出框 -->
    <el-dialog
      class="remarks_dialog"
      :title='RemarksTitle'
      :visible.sync="RemarksShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="remarksClosed"
    >
      <div class="remarks-tip"><span>余额{{rowOpt=='00'?'上账':'下账'}}</span></div>
      <div style="margin-bottom: 10px">备注内容</div>
      <el-input
        maxlength="200"
        type="textarea"
        :rows="4"
        placeholder="请输入备注内容,最多200字"
        v-model="masterNote"
        show-word-limit
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="remarksSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :width="dialogWidth"
      @closed="handleClose"
    >
      <div class="remarks-tip"><span>余额{{rowOpt=='00'?'上账':'下账'}}</span></div>
      <div v-if="btnType=='voucher'">
        <div style="margin-bottom: 10px;font-size:14px;color:#333333">凭证截图<span style="font-size:12px;color:#666666;">(点击可放大查看)</span></div>
        <div class="demo-image__preview">
          <ElImgUploadGroup
            :length="3"
            :fileSize="5"
            :isBigImg="true"
            :exist-img-list="voucherList"
            @handleChange="handleUploadGroupChange($event)"
          />
        </div>
        <div class="command">
          <div style="width: 88px;">动态口令：</div>
          <el-input
            size="small"
            v-model="command"
            placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          ></el-input>
        </div>
      </div>
      <div style="font-size:14px;color:#333333" v-if="btnType=='record'">
        <div><span style="margin-bottom: 10px;">操作人：</span><span style="color:#666666;">{{operation.adminName}}</span></div>
        <div><span>时间：</span><span style="color:#666666;">{{operation.createTime}}</span></div>
        <!-- <div v-if="rowOpt!='00'"><span>原因：</span><span style="color:#666666;">{{operation.cause}}</span></div> -->
        <div><span>原因：</span><span style="color:#666666;">{{operation.cause}}</span></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="btnType=='record'" @click.native="handleClose">关闭</el-button>
        <el-button v-if="btnType=='voucher'" @click.native="handleClose">取消</el-button>
        <el-button type="primary" v-if="btnType=='voucher'" :loading="voucherBtnLoading" @click.native="handleSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 微信流水号弹出框 -->
    <el-dialog
      class="followNo_dialog"
      :title='FollowNoTitle'
      :visible.sync="FollowNoShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="FollowNoClosed"
    >
      <div style="margin-bottom: 10px">微信流水号内容</div>
      <el-input
        maxlength="40"
        type="textarea"
        :rows="4"
        placeholder="请输入微信流水号内容"
        v-model="wechatFollowNo"
        @input="wechatFollowNoInput"
        show-word-limit
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="FollowNoClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="FollowNoSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 核账 -->
    <DialogAction v-bind="callAccountsProps"
                  @close="callAccountsProps.show = false"
                  @config="callAccountsConfig">
      <template v-slot>
        <!-- 核对账单 -->
        <div class="call_accounts_bill" v-if="callAccountsProps.currentData.approvedType === '00'">
          <h3 class="lv_fc">核账信息</h3>
          <div class="call_accounts_info">
            <!-- <p>回收商：{{callAccountsProps.currentData.merchantName||'--'}}</p> -->
            <p>{{callAccountsProps.currentData.accountType == 'company' ? '门店商：' : '回收商：'}}{{callAccountsProps.currentData.enterpriseName||'--'}}</p>
            <p>{{ callAccountsProps.mark }}金额：{{callAccountsProps.currentData.amountPrice}}元</p>
            <p>{{callAccountsProps.mark}}人：{{callAccountsProps.currentData.adminName}}</p>
            <p>{{callAccountsProps.mark}}时间：{{callAccountsProps.currentData.createTime}}</p>
          </div>
          <h3 class="lv_fc">核账操作</h3>
          <div class="call_accounts_check">
            <el-radio-group v-model="callAccountsProps.state">
              <el-radio label="01">通过</el-radio>
              <el-radio label="02">不通过</el-radio>
            </el-radio-group>
          </div>
          <el-input
              v-if="callAccountsProps.state === '02'"
              style="width: 70%;"
              :autosize="{ minRows: 4}"
              type="textarea"
              placeholder="核账不通过备注（必填）"
              v-model="callAccountsProps.note"
              maxlength="200"
              show-word-limit
          />
        </div>
        <!--  记录查看  -->
        <div class="call_accounts_finish" v-if="callAccountsProps.currentData.approvedType === '01' || callAccountsProps.currentData.approvedType === '02'">
          <p>核账结果：<span class="lv_fc">{{ callAccountsProps.currentData.approvedType === '01' ?'已通过':'未通过' }}</span></p>
          <p style="margin-top: 10px;" v-if="callAccountsProps.currentData.approvedType === '02'">未通过原因：<span class="lv_fc_red">{{callAccountsProps.currentData.approvedNote||'--'}}</span></p>
          <div class="call_accounts_result">
            <p>核账人：{{callAccountsProps.currentData.approvedAdminName||'--'}}</p>
            <p>核账时间：{{callAccountsProps.currentData.approvedTime||'--'}}</p>
          </div>
        </div>
      </template>
    </DialogAction>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImgUploadGroup from '../../../components/global/components/elements/upload-group-element.vue'
import DialogAction from "@/components/common/DialogAction.vue";

export default {
  name: "Table",
  components: {DialogAction, ElImgUploadGroup },
  data() {
    return {
      // 核账弹窗
      callAccountsProps:{
        show:false,
        // 上账还是下账
        mark:"",
        // 标题
        title:"核对账单",
        currentData:{},
        // 通过和不通过选择01代表通过（），02代表不通过
        state:null,
        // 不通过原因
        note:"",
        // 核账弹窗控制
        type:"",
        configText:"确定"
      },
      isExport: false,
      loading: false,
      seachDataList: [],
      auditStatus: '',
      RemarksTitle: "",
      RemarksShow: false,
      masterNote: "",
      butLoading: false,
      voucherBtnLoading: false,
      rowId: "",
      rowOpt: "",
      btnType: "",
      command: "",
      wechatFollowNo: "",
      operation: {},
      applyNumber: {},
      voucherList: [],
      page:{
        pageNum:1,
        pageSize:10
      },
      pageNum:1,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      FollowNoShow: false,
      dialogTitle: "", // 标题
      FollowNoTitle: "",
      dialogWidth: '600px',
      dialongDecide: "",
      tableColumns: [
        { slotName: "accountType" },//商户类型  新增
        { slotName: "enterpriseName" },//商户名称 新增
        { slotName: "operationAccount" },//资金账户  新增
        { slotName: "enterpriseAddress" },
        { label: "联系人", prop: "contacts" },
        { label: "联系电话", prop: "contactsPhone" },
        { slotName: "opt" },
        { label: "金额", prop: "amountPrice" },
        { label: "当时账户结余", prop: "beforeAmount" },
        { label: "当前账户余额", prop: "balance" },
        { slotName: "payeeWay" },//转款方式
        { slotName: "voucher" },
        { label: "时间", prop: "createTime" },
        { slotName: "wechatFollowNo" },
        { slotName: "callAccounts" },
        { slotName: "note" },
        { slotName: "operation" }
      ]
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
  },
watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    }
  },
  methods: {
    setMark(v){
      switch (v){
        case "00":
          return "上账";
        case "01":
          return "下账";
      }
    },
    // 核对账单点击
    callAccountsClick(currentData){
      console.log('核对账单点击',currentData);
      this.callAccountsProps = {
        show:true,
        // 上账还是下账
        mark:this.setMark(currentData.opt),
        // 标题
        title:currentData.approvedType === '00'?"核对账单":"核对记录",
        currentData,
        // 通过和不通过选择
        state:null,
        // 不通过原因
        note:""
      };

    },
    // 核对账单确定
    callAccountsConfig(){
      const {title,state,note,currentData,mark} = this.callAccountsProps;
      if(currentData.approvedType === '00'){
        if(state === '02' && !note){
          return this.$message.error("请输入不通过原因");
        }
        if(!state){
          return this.$message.error("请选择核账结果");
        }
        return _api.approved({
          note,
          id:currentData.id,
          state
        }).then(r=>{
          this.callAccountsProps = {
            ...this.callAccountsProps,
            show:false
          };
          this.handleCurrentChange(this.page.pageNum);
        })
      }
      this.callAccountsProps = {
        ...this.callAccountsProps,
        show:false
      };
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        isFindRepeat: this.SeachParams.isFindRepeat,
        isFindEmpty: this.SeachParams.isFindEmpty,
        opt: this.auditStatus,
        contactsPhone: this.SeachParams.contactsPhone,
        // merchantName: this.SeachParams.merchantName,
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        note: this.SeachParams.note,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        approvedType:this.SeachParams.approvedType
      };
      this.loading = true
      _api.getAccountList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.list.records;
          this.applyNumber.all = res.data.all
          this.applyNumber.addNum = res.data.addNum
          this.applyNumber.subNum = res.data.subNum
          this.page.total = res.data.list.total;
          this.page.pageNum = res.data.list.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 弹出备注弹框
    handleBtn(row,type){
      console.log(row);
      this.rowOpt = row.opt
      if(type == 'remark'){
        if(row.masterNote){
            this.RemarksTitle = '修改备注'
        }else{
            this.RemarksTitle = '添加备注'
        }
        this.rowId = row.id
        this.masterNote = row.note
        this.RemarksShow = true
      }else{
        this.dialogWidth = '370px'
        this.operation = {
          adminName: row.adminName,
          createTime: row.createTime,
          cause: row.cause
        }
        this.btnType = 'record'
        this.dialogTitle = '操作记录-'+ row.enterpriseName,//row.merchantName
        this.dialogVisible = true
      }
    },
    // 关闭备注弹框
    remarksClosed() {
        this.RemarksShow = false
        this.masterNote = ""
    },
    // 备注确定提交
    remarksSure() {
        this.butLoading = true
        _api.accountNote({id:this.rowId,note:this.masterNote}).then(res => {
            console.log(res);
            if (res.code === 1) {
                this.$message.success(res.msg)
                this.RemarksShow = false
                this.handleCurrentChange()
            }
            this.butLoading = false
        }).catch(err => {
            this.butLoading = false
        })
    },
    // 上账/下账 点击
    handBtn(type) {
      this.$router.push({path:"/payment/setAccount",query:{type}})
    },
    btnClick(type){
      this.auditStatus = type
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
    // 查看凭证
    seeVoucher(row) {
      this.dialogWidth = '500px'
      this.btnType = 'voucher'
      this.dialogVisible = true
      this.rowOpt = row.opt
      this.rowId = row.id
      this.dialogTitle = '查看凭证-'+ row.enterpriseName //row.merchantName
      _api.getVoucher({accountRecordId: row.id}).then(res => {
        if(res.code === 1){
          this.voucherList = res.data
        }
      })
    },
    handleClose() {
      this.voucherList = []
      this.adminName = ''
      this.command = ""
      this.dialogVisible = false
    },
    // 添加微信流水单号
    setFollowNo(row,type) {
      this.rowId = row.id
      this.wechatFollowNo = row.wechatFollowNo
      if(type == 'add'){
        this.FollowNoTitle = '添加微信流水号'
      }else{
        this.FollowNoTitle = '修改微信流水号'
      }
      this.FollowNoShow = true
    },
    FollowNoClosed() {
      this.FollowNoShow = false
    },
    FollowNoSure() {
      if(!this.wechatFollowNo){
        this.$message.error('请输入微信流水单号')
        return
      }
       this.butLoading = true
        _api.wechatFollowNo({id:this.rowId,wechatFollowNo:this.wechatFollowNo}).then(res => {
            console.log(res);
            if (res.code === 1) {
                this.$message.success(res.msg)
                this.FollowNoShow = false
                this.handleCurrentChange()
            }
            this.butLoading = false
        }).catch(err => {
            this.butLoading = false
        })
    },
    // 上传图片后，将图片信息保存到对应参数中
		handleUploadGroupChange (list) {
      console.log(list);
			if (Array.isArray(list)) {
        this.voucherList = list
			}
		},
    handleSure() {
      if(!this.voucherList.length){
        this.$message.error('至少上传一张凭证。')
        return
      }else if(!this.command){
        this.$message.error('请输入动态口令')
        return
      }
      this.voucherBtnLoading = true
      let params = {
        accountRecordId: this.rowId,
        voucher: this.voucherList,
        command: this.command
      }
      _api.voucherUpdate(params).then(res => {
        this.$message.success('操作成功')
        this.voucherBtnLoading = false
        this.dialogVisible = false
      }).catch(()=>{
        this.voucherBtnLoading = false
      })
    },
    // 导出
    clickExcel() {
      const params = {
        opt: this.auditStatus,
        contactsPhone: this.SeachParams.contactsPhone,
        // merchantName: this.SeachParams.merchantName,
        merchantId: this.SeachParams.merchantId,
        note: this.SeachParams.note,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        approvedType:this.SeachParams.approvedType,
        isFindRepeat: this.SeachParams.isFindRepeat,
        isFindEmpty: this.SeachParams.isFindEmpty,
      }
      this.isExport=true
      _api.accountExportExcel(params).then(res => {
        console.log(res)
        // if (this.SeachParams.startTime) {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend",  ()=> { //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || '导出失败')
              }
            } catch (err){
              const fileName = "账务列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          })
          reader.readAsText(blob, 'utf-8'); // 设置读取的数据以及返回的数据类型为utf-8
          this.isExport=false
      }).catch(err => {
        this.isExport=false
        console.log(err)
      });
    },
    wechatFollowNoInput(e) {
      this.wechatFollowNo = e.replace(/[\W]/g,'')
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .button_top{
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    width: 432px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    .active{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }
    .none{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
    // 备注说明
    .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
    }
    .operation-btn{
        /deep/.el-button{
            margin-left: 0;
        }
    }
  .remarks-tip{
    color: #0981FF;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }
  .command{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.call_accounts_info{
  margin: 10px 0;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  >p{
    margin-bottom: 10px;
  }
  >p:nth-of-type(odd){
    min-width: 56%;
    width: 56%;
    max-width: 56%;
  }
  >p:nth-of-type(even){
    min-width: 40%;
    width: 40%;
    max-width: 40%;
  }
}

.call_accounts_check{
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 14px;
}

.call_accounts_result{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

</style>
